<template>
    <TaxAccountDetail :accounts="accounts" :account="account" @on-account-change="onAccountChange" />
</template>
<script>
import Api from "../taxAccount.api";
import TaxAccountDetail from "./ui/taxAccountDetail.container";
export default {
    components: { TaxAccountDetail },
    data: function() {
        return {
            clientId: undefined,
            accounts: [],
            transactions: [],
            account: undefined,
        };
    },
    async created() {
        // this.$waiting.start("loading");
        // this.accounts = await Api.getAccounts();
        // this.$waiting.end("loading");
        document.title = "Skattekonto - Konto";
    },
    methods: {
        onAccountChange: async function(client) {
            if (client) {
                this.clientId = client.value;
                this.account = await Api.getAccount(this.clientId);
            } else {
                this.account = undefined;
            }
        },
    },
};
</script>

<template>
    <el-table :data="transactionsMaped" v-loading="$waiting.is('loading')" class="w-full" :row-class-name="tableRowClassName">
        <el-table-column prop="date" label="Datum" width="120" />
        <el-table-column prop="text" label="Text" />
        <el-table-column prop="amount" label="Belopp" align="right" sortable="custom" width="120">
            <template slot-scope="scope">
                {{ scope.row.amount | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column prop="balance" label="Saldo" align="right" width="120" sortable />
    </el-table>
</template>

<script>
import moment from "moment";
export default {
    props: {
        transactions: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        transactionsMaped() {
            if (!this.transactions) return [];
            return this.transactions.map(item => ({
                id: item.id,
                order: !item.upcoming ? item.order : "10000",
                upcoming: item.upcoming,
                amount: item.amount,
                balance: !item.upcoming ? this.$options.filters.swedishNumberFormat(item.balance) : "",
                text: item.text,
                date: moment
                    .utc(item.date)
                    .local()
                    .format("YYYY-MM-DD"),
            }));
        },
    },

    methods: {
        tableRowClassName({ row }) {
            if (row.upcoming == true) {
                return "selected-row";
            }
            return "";
        },
    },
};
</script>
<style>
.el-table .selected-row {
    background: #e8f6ff;
}
</style>

<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div class="column field is-grouped is-full">
                        <!-- <SearchClient class="is-expanded" placeholder="Sök klient..." :options="accounts.list" @on-selected="onClientChange" /> -->
                        <ClientsSearch @on-selected="onClientChange" />
                    </div>
                    <div class="column is-half">
                        <dl v-if="account !== undefined">
                            <dt>Saldo skatteverket</dt>
                            <dd>{{ account.balanceSkv | swedishNumberFormat }}</dd>
                            <dt>Saldo kronofogden</dt>
                            <dd>{{ account.balanceBailiff | swedishNumberFormat }}</dd>
                            <dt>Uppdaterad</dt>
                            <dd>{{ updated }}</dd>
                        </dl>
                    </div>
                </div>
                <div class="column is-half" />
            </div>
            <div class="columns">
                <TransactionTable :transactions="transactions" />
            </div>
        </div>
    </section>
</template>
<script>
// import SearchClient from "../../../../components/searchClient";
import TransactionTable from "./TransactionTable";
import moment from "moment";

export default {
    components: { TransactionTable, ClientsSearch: () => import("@/components/clientsSearch.vue") },

    props: ["accounts", "account"],

    computed: {
        updated: function() {
            return moment
                .utc(this.account.updated)
                .local()
                .format("YYYY-MM-DD");
        },

        transactions: function() {
            if (this.account === undefined) return [];
            return this.account.taxAccountTransactions;
        },
    },

    methods: {
        onClientChange: function(item) {
            this.$emit("on-account-change", item);
            // document.title = "Skattekonto - " + item.name.split(", ")[1];
        },
    },
};
</script>
<style scoped>
dl {
    display: flex !important;
    flex-wrap: wrap !important;
}
dt {
    width: 33% !important;
    font-weight: 500;
    font-size: 13px;
    color: darkslategrey;
}
dd {
    margin-left: auto !important;
    font-weight: 500;
    font-size: 13px;
    width: 66% !important;
    color: darkslategrey;
}
</style>
